import $ from 'jquery'

export default function initIframeWraps(){
  jQuery('.wysiwyg-editor iframe').filter(function () {
    return this.src.match(/(youtube\.com|youtu\.be)/i);
  }).wrap("<div class='responsive-iframe'></div>");
  jQuery('.wysiwyg-editor iframe').filter(function () {
    return this.src.match(/(vimeo\.com)/i);
  }).wrap("<div class='responsive-iframe'></div>");
}
