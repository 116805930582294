import 'slick-carousel';

import klugeCSP from '/lib/csp-kluge'
import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'
import initBlogFunctions from '/lib/blog'
import initEventFunctions from './lib/events';
import initPartnerFunctions from './lib/partnerships';
import initScrollToElement from './lib/scroll-to-element';
import initIframeWraps from './lib/iframes';

initGreenSock()
initHeadroom()
initMobileNav()
initA11yFunctions()
klugeCSP()
initBlogFunctions()
initEventFunctions()
initPartnerFunctions()
initScrollToElement()
initIframeWraps()
