import $ from 'jquery'

const $doc = $(document)

function partnerFuncs() {
  const $sliders = $('.js-featured-slider:not(.slick-initialized)')
  if ($sliders.length) {
    $sliders.each(function () {
      let $el = $(this);
      $el.slick({
        arrows: true,
        dots: true,
        speed: 600,
        prevArrow: '<button type="button" class="slick-prev"><span class="u-sr-only">Previous</span><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L26 11" stroke="black"/><path d="M11 21L1.5 11L11 1" stroke="black"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="u-sr-only">Next</span><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 11L8.9407e-07 11" stroke="black"/><path d="M15 21L24.5 11L15 1" stroke="black"/></svg></button>'
      });
    });
  }
  $doc.on('click', '.js-open-filters', function () {
    let el = $(this)
    el.attr('aria-expanded', 'true')
    $('#blog-filters').addClass('active')
  });
  $doc.on('click', '.js-close-filters', function () {
    let el = $(this)
    el.attr('aria-expanded', 'false')
    $('#blog-filters').removeClass('active')
  });
  $doc.on('click', '.js-filter-accordion', function(){
    let el = $(this);
    el.toggleClass('active');
    el.parent().parent().parent().find('.partnerships__checklist').slideToggle();
    if ( el.hasClass('active') ) {

    } else {

    }
  });
}
function ajaxLoadPosts(page = 1, cat = '', loc = '', neighborhood = '', use = '', type = '', status = '' ) {
  const data = {
    page: page,
    cat: cat,
    loc: loc,
    neighborhood: neighborhood,
    use: use,
    type: type,
    status: status,
    action: 'ajax_partnership_filter'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('body').find('#posts-wrap').html('<div class="loader"><svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox = "0 0 100 100" enable - background="new 0 0 0 0" xml: space = "preserve" ><rect x="38" y="35" width="4" height="10" fill="#76DCB6"><animateTransform attributeType="xml" attributeName="transform" type="translate" values="0 0; 0 20; 0 0" begin="0" dur="0.6s" repeatCount="indefinite" /></rect><rect x="48" y="35" width="4" height="10" fill="#76DCB6"><animateTransform attributeType="xml" attributeName="transform" type="translate" values="0 0; 0 20; 0 0" begin="0.2s" dur="0.6s" repeatCount="indefinite" /></rect><rect x="58" y="35" width="4" height="10" fill="#76DCB6"><animateTransform attributeType="xml" attributeName="transform" type="translate" values="0 0; 0 20; 0 0" begin="0.4s" dur="0.6s" repeatCount="indefinite" /></rect></svg ></div>');
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('#posts-wrap').html('<p class="no-results">No results found, please try a different combination of filters.</p>');
      }
      else {
        $('body').find('#posts-wrap').html(htmlResponse);
      }
    },
    error: function () {
      $('body').find('#posts-wrap').html('<p class="no-results">Sorry, no results found.</p>');
    },
    complete: function () {
      //$('body').find('#posts-wrap').css('opacity', 1);
    }
  });
}

function filterPosts() {
  let page = 1;
  let cat;
  let loc;
  let neighborhood;
  let use;
  let type;
  let status;

  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('cat')) {
      cat = searchParams.get('cat');
      $('#'+cat).prop('checked', true);
    }
    if (searchParams.has('loc')) {
      loc = searchParams.get('loc');
      $('#' + loc).prop('checked', true);
    }
    if (searchParams.has('neighborhood')) {
      neighborhood = searchParams.get('neighborhood');
      $('#' + neighborhood).prop('checked', true);
    }
    if (searchParams.has('use')) {
      use = searchParams.get('use');
      $('#' + use).prop('checked', true);
    }
    if (searchParams.has('type')) {
      type = searchParams.get('type');
      $('#' + type).prop('checked', true);
    }
    if (searchParams.has('status')) {
      status = searchParams.get('status');
      $('#' + status).prop('checked', true);
    }
    if (searchParams.has('cat') || searchParams.has('loc') || searchParams.has('neighborhood') || searchParams.has('use') || searchParams.has('type') || searchParams.has('status')) {
      ajaxLoadPosts(1, cat, loc, neighborhood, use, type, status);
    }
  }
  $('.js-post-filter').on('change', function (event) {
    cat = $("#cat-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    loc = $("#loc-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    neighborhood = $("#neighborhood-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    use = $("#use-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    type = $("#type-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    status = $("#status-filter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    if ( cat.length == 0 ) {
      $('#cat-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#cat-filter').find('.js-filter-count').text(cat.length).removeClass('hidden')
    }
    if (loc.length == 0) {
      $('#loc-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#loc-filter').find('.js-filter-count').text(loc.length).removeClass('hidden')
    }
    if (neighborhood.length == 0) {
      $('#neighborhood-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#neighborhood-filter').find('.js-filter-count').text(neighborhood.length).removeClass('hidden')
    }
    if (use.length == 0) {
      $('#use-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#use-filter').find('.js-filter-count').text(use.length).removeClass('hidden')
    }
    if (type.length == 0) {
      $('#type-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#type-filter').find('.js-filter-count').text(type.length).removeClass('hidden')
    }
    if (status.length == 0) {
      $('#status-filter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#status-filter').find('.js-filter-count').text(status.length).removeClass('hidden')
    }
    ajaxLoadPosts(1, cat, loc, neighborhood, use, type, status);
  });

  $doc.on('click', '.js-posts-pagination button', function () {
    page = $(this).data('page');
    ajaxLoadPosts(page, cat, loc, neighborhood, use, type, status);
  });
  $doc.on('click', '.js-filter-clear', function () {
    $('.js-post-filter').prop('checked', false);
    $('.js-filter-count').text('').addClass('hidden');
    ajaxLoadPosts(1, [], [], [], [], [], []);
  });
}

export default function initPartnerFunctions(){
  if ($('body').hasClass('page-template-partnerships')) {
    partnerFuncs();
    $(document).ready(filterPosts);
  }
}
