export default function initScrollToElement() {
  if (window.location.hash) {
    const hash = window.location.hash.substring(1)
    const element = document.getElementById(hash)
    if (element) {
      setTimeout(() => {
        element.scrollIntoView()
      } , 1000)
    }
  }
}
